const firebaseConfig = {
  apiKey: "AIzaSyBxKVi_55RwO4qr6jiqwFHWg7tGwVPx_K8",
  authDomain: "petscale-54fcc.firebaseapp.com",
  projectId: "petscale-54fcc",
  storageBucket: "petscale-54fcc.appspot.com",
  messagingSenderId: "775727941138",
  appId: "1:775727941138:web:41e180dab03844f340bacb",
  measurementId: "G-H8SDP8L8K9",
};

export default firebaseConfig;
